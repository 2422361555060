export const blog = [
  {
    id: "700",
    name: "Remove Elements From JavaScript Array",
    image: "https://i.ibb.co/hVCFNMG/javascript-illustration.png",
    detail:
      "This is my first post on my personal blog site. So I am very excited. Here I am telling you about how to remove elements from a javascript Array. Let’s go… You can remove array elements in different ways. I applied some methods to remove an element from an array.",
    technology: ["JavaScript"],
    link: "http://blog.mahbubhasanhira.com/remove-elements-from-javascript-array/",
    date: "Dec 30 2020",
  },
  {
    id: "701",
    name: "Convert File to Base64 with React-File-Base in React Application",
    image:
      "http://blog.mahbubhasanhira.com/wp-content/uploads/2020/12/base64.png",
    detail:
      "In this blog, I told you, how to convert image, video, pdf, zip, etc with react-file-base64. The first step we need a react app. I have already installed a simple react app with npx create-react-app your-app-name commend. Then clear App.js and just take the h1 and h2 tag for example.",
    technology: ["React JS", "File Base64"],
    link: "http://blog.mahbubhasanhira.com/convert-file-to-base64-with-react-file-base-in-react-application/",
    date: "Dec 31 2020",
  },
];
