import { motion } from "framer-motion";
import React, { useContext } from "react";
import {
	body_dark_bg_color,
	sidebar_dark_bg_color,
	white,
} from "../../../assets/color";
import { ThemeProvider } from "../../../ThemeContext";
import styles from "./button.module.scss";

const Button = (props) => {
	const { darkTheme } = useContext(ThemeProvider);
	const { customStyle, name, disabled } = props;

	return (
		<motion.button
			style={{
				color: customStyle?.color
					? customStyle?.color
					: darkTheme
					? sidebar_dark_bg_color
					: white,
				backgroundColor: customStyle?.backgroundColor
					? customStyle?.backgroundColor
					: darkTheme
					? white
					: body_dark_bg_color,
				...customStyle,
			}}
			className={styles.button}
			whileHover={{ scale: props.scaleHover || 1.08 }}
			whileTap={{ scale: props.scaleTap || 0.9 }}
			transition={{duration: props.duration || .3 }}
			onClick={props?.handleEvent}
			disabled={disabled}
		>
			<span>{name || props.children}</span>
		</motion.button>
	);
};

export default Button;
