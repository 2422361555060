import React, { useContext, useEffect, useState } from "react";
import {
	card_dark_bg_color,
	off_white_color,
	sidebar_dark_bg_color,
	side_bar_white_bg_color
} from "../../../assets/color";
import { blog } from "../../../projectData/blog";
import { ThemeProvider } from "../../../ThemeContext";
import Button from "../../common/Button/Button";
import ItemSpan from "../../common/ItemSpan/ItemSpan";
import PageHeadingTag from "../../common/PageHeadingTag/PageHeadingTag";
import styles from "./Blog.module.scss";

const Blog = () => {
	const [blogs, setBlogs] = useState([]);
	const { darkTheme } = useContext(ThemeProvider);

	useEffect(() => {
		setBlogs(blog);
	}, []);
	return (
		<div>
			<PageHeadingTag bgName="Writing">Skill Share</PageHeadingTag>
			<div className={styles.wrapper}>
				<div className={styles.blogContainer}>
					{
						blogs.map((blog, index) => (
							<div
								style={{
									backgroundColor: darkTheme
										? card_dark_bg_color
										: side_bar_white_bg_color,
									color: darkTheme ? off_white_color : sidebar_dark_bg_color,
								}}
								className={styles.blogCard}
								key={blog?.id + index}
							>
								<a
									className={styles.card}
									target="_blank"
									rel="noreferrer"
									href={blog?.link}
								>
									<div className={styles.cardTop}>
										<img loading="lazy" src={blog?.image} alt="" />
									</div>
									<div className={styles.cardBody}>
										<h5 className={styles.cardTitle}>
											{blog?.name.length < 65
												? blog.name
												: blog?.name?.slice(0, 65) + "....."}
										</h5>
										<p className={styles.cardDetail}>
											{blog?.detail?.length < 155
												? blog.detail
												: blog?.detail?.slice(0, 155) + "....."}
										</p>
									</div>
									<div className={styles.cardBottom}>
										<div className={styles.technology}>
											{blog?.technology?.map((item, index) => (
												<ItemSpan
													margin="0 5px 0 0"
													key={index + item}
													wBgColor="white"
													name={item}
												/>
											))}
										</div>
										<div className={styles.date}>{blog?.date}</div>
									</div>
								</a>
							</div>
						))
					}
				</div>
				<div
					style={{
						display:"block",
						textAlign: "center",
						margin: "50px auto",
					}}
				>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://blog.mahbubhasanhira.com"
					>
						<Button customStyle={{ borderRadius: "5px" }}>See All Blog</Button>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Blog;
