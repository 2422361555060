import React, { useContext } from "react";
import { ThemeProvider } from "../../../ThemeContext";

const ItemSpan = ({ name, wBgColor, margin }) => {
	const { darkTheme } = useContext(ThemeProvider);
	const techItem = {
		display: "inline-block",
		width: "fit-content",
		fontSize: "14px",
		margin: margin || "3px 5px",
		padding: "3px 6px",
		borderRadius: "4px",
		color: darkTheme ? "#f8f9fa" : "inherit",
		backgroundColor: darkTheme ? "#4B5769" : wBgColor,
	};
	return <span style={techItem}>{name}</span>;
};

export default ItemSpan;
