import React, { useContext } from "react";
import {
	crimson_color,
	green_color,
	sidebar_dark_bg_color,
	white,
} from "../../../assets/color";
import { ThemeProvider } from "../../../ThemeContext";
import styles from "./PageHeadingTag.module.scss";

const PageHeadingTag = (props) => {
	const { bgName } = props;
	const { darkTheme } = useContext(ThemeProvider);
	return (
		<div className={styles.pageHeading}>
			<div>
				<h1>{bgName}</h1>
				<div>
					<p
						style={{
							color: darkTheme ? white : sidebar_dark_bg_color,
						}}
					>
						{props.children}
					</p>
					<span
						style={{ backgroundColor: darkTheme ? green_color : crimson_color }}
					></span>
				</div>
			</div>
		</div>
	);
};

export default PageHeadingTag;
